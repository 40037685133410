html, body {
  margin: 0;
  padding: 0;
}

.esri-view .esri-view-surface--inset-outline:focus::after,
.esri-widget *:focus {
  outline: 0;
}

#mapView {
  position: absolute;
  top: 54px;
  left: 0;
  right: 20px;
  bottom: 50px;
}

#mapCanvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  overflow: hidden;
}

#mapControls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-content: center;
  background: rgba(255, 255, 255, 0.75);
  padding: 20px;
}

#zoomDiv {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
}

#sliderDiv {
  position: relative;
  height: auto;
  background: none;
  margin: 0 30px;
}
#sliderDiv .esri-slider__track {
  background: #515050;
  height: 3px;
}

#sliderDiv .esri-slider__thumb {
  background: #26768b;
  border-color: #26768b;
}

#sliderDiv .esri-slider__label {
  position: absolute;
  top: auto;
  bottom: -30px;
  color: #26768b;
  font-weight: bold;
  z-index: 10;
}

#sliderDiv .esri-slider__content {
  position: relative;
  z-index: 10;
}

#sliderDiv .esri-slider__min {
  position: absolute;
  left: -24px;
  bottom: 0px;
  font-weight: bold;
  z-index: 3;
}

#sliderDiv .esri-slider__max {
  position: absolute;
  right: -26px;
  bottom: 1px;
  font-weight: bold;
  z-index: 3;
}

.appWrap * {
  box-sizing: border-box;
}

.appWrap {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 40px;
  background: rgb(38,118,139);
  background: linear-gradient(0deg, rgba(38,118,139,1) 0%, rgba(55,168,200,1) 100%);
}

.appWrap .title {
  color: white;
  font-size: 50px;
  line-height: 1.2;
  margin-bottom: 23px;
}

.appWrap .card {
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.appWrap .card .search {
  border-bottom: 1px solid #C0C0C0;
  display: flex;
  justify-content: center;
}

.appWrap .card .goToFilters {
  display: none;
  border-bottom: 1px solid #C0C0C0;
}
.appWrap .card .goToFilters a {
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 10px;
  font-size: 15px;
  line-height: 1;
  color: #26768b;
}

.appWrap .esri-popup__main-container {
  width: 235px;
  max-width: 235px;
}
.esri-popup__action-text {
  display: block !important;
}
.appWrap .esri-popup__navigation {
  margin: 0;
}
.esri-popup__content {
  font-size: 15px;
  line-height: 1.2;
  color: #454545;
}
.esri-popup__content p {
  margin: 0 0 12px 0;
}
.esri-popup__inline-actions-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.esri-popup__inline-actions-container .esri-popup__button .esri-popup__icon {
  display: none;
}
.esri-popup__inline-actions-container .esri-popup__button {
  background: #26768C;
  color: #fff;
  line-height: 15px;
  padding: 10px;
  display: block;
  width: 100%;
  border: 0;
  margin: 0;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}

#searchDiv {
  flex: 1;
  position: relative;
}

#searchDiv .esri-search__container {
  flex: 1;
  position: relative;
}

#searchDiv .esri-search__form {
  flex: 1;
  display: flex;
}

#searchDiv .esri-search__submit-button {
  order: 1;
  height: auto;
  width: auto;
  align-self: stretch;
  padding: 25px;
  border-left: 0;
  display: none;
}

#searchDiv .esri-search__clear-button {
  order: 1;
  height: auto;
  width: auto;
  align-self: stretch;
  padding: 25px;
  display: none;
}

#searchDiv .esri-search__input-container {
  order: 2;
}

.appWrap .card .search input {
  flex: 1;
  height: auto;
  border: 0;
  padding: 20px 5px;
  font-size: 27px;
  line-height: 27px;
  color: #454545;
  background: none;
  align-self: center;
}

.appWrap .card .search #locateDiv {
  border: 0;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: auto;
  height: auto;
  border-left: 1px solid #C0C0C0;
}

.appWrap .card .search #locateDiv:hover {
  background: #f3f3f3;
}

.appWrap .card .search .submit,
.appWrap .card .search .clear,
.appWrap .card .search .gps {
  border: 0;
  padding: 25px 34px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.appWrap .card .search .submit svg,
.appWrap .card .search .clear svg,
.appWrap .card .search .gps svg {
  width: 26px;
  height: 26px;
  display: block;
  fill: #737373;
  color: #737373;
}
.appWrap .card .search .submit svg {
  width: 24px;
  height: 24px;
}

.appWrap .card .search .gps {
  border-left: 1px solid #C0C0C0;
}

.appWrap .card .sides {
  flex: 1;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.appWrap .card .sides .left {
  position: relative;
  width: 380px;
  min-width: 380px;
  padding: 18px 24px 50px 34px;
  display: flex;
  flex-direction: column;
}

.appWrap .card .sides .left .heading {
  color: #454545;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 18px;
  font-weight: 600;
}

.appWrap .card .sides .left .filterButtons {
  margin: 0 -2px;
  display: flex;
}

.appWrap .card .sides .left .filterButtons button {
  background: rgba(55,168,200, 1);
  border-radius: 10px;
  border: 1px solid #C0C0C0;
  color: #454545;
  background: #fff;
  padding: 7px;
  margin: 0 2px;
  flex: 1;
  cursor: pointer;
  font-size: 15px;
  line-height: 1;
  font-weight: 600;
}

.appWrap .card .sides .left .filterButtons button.active {
  background: rgba(38,118,139,1);
  border-color: rgba(38,118,139,1);
  color: #fff;
}

.appWrap .card .sides .left .filterCheckboxes {
  border-top: 3px solid #454545;
  border-bottom: 3px solid #454545;
  margin-top: 34px;
  padding: 10px 0;
  overflow-y: auto;
  flex: 1;
}

.appWrap .card .sides .left .filterCheckboxes.hidden {
  opacity: 0.25;
}

.appWrap .card .sides .left .filterCheckboxes .checkbox {
  position: relative;
  display: flex;
  padding: 10px 70px 10px 0;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.1;
}

.appWrap .card .sides .left .filterCheckboxes .checkbox.hidden {
  display: none;
}

.appWrap .card .sides .left .filterCheckboxes .checkbox span {
  flex: 1;
}

.appWrap .card .sides .left .filterCheckboxes .checkbox .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -12px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appWrap .card .sides .left .filterCheckboxes .checkbox .icon svg {
  height: 24px;
  width: 24px;
  display: block;
  fill: #BBBBBB;
  color: #BBBBBB;
}

.appWrap .card .sides .left .filterCheckboxes .checkbox.checked .icon svg {
  fill: #26768C;
  color: #26768C;
}

.appWrap .card .sides .right {
  flex: 1;
  position: relative;
}

.appWrap .card .sides .right.detailsShowing #mapView {
  margin-right: 300px;
}

.appWrap .card .sides .right .resultPanel {
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 0;
  bottom: 10px;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px 0 0 20px;
  width: 340px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}

.appWrap .card .sides .right .resultPanel.hidden {
  margin-right: -360px;
}

.appWrap .card .sides .right .resultPanel .heading {
  display: flex;
  align-items: center;
}

.appWrap .card .sides .right .resultPanel .heading span {
  color: #454545;
  padding: 10px;
  margin: 20px 10px;
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
  flex: 1;
}

.appWrap .card .sides .right .resultPanel .heading button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  background: none;
  padding: 0;
  border: 0;
  cursor: pointer;
}

.appWrap .card .sides .right .resultPanel .heading button svg {
  width: 13px;
  height: auto;
  display: block;
  fill: #454545;
  color: #454545;
}

.appWrap .card .sides .right .resultPanel .results {
  border-top: 3px solid #454545;
  border-bottom: 3px solid #454545;
  padding: 10px 0;
  margin: 0 10px;
  overflow-y: auto;
}

.appWrap .card .sides .right .resultPanel .results .result {
  position: relative;
  background: #fff;
  color: #454545;
  padding: 12px 40px 16px 12px;
}

.appWrap .card .sides .right .resultPanel .results .result.active .name {
  font-weight: bold;
}

.appWrap .card .sides .right .resultPanel .results .result:nth-child(even) {
  background: #37A8C80D;
}

.appWrap .card .sides .right .resultPanel .results .result .toggleDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  cursor: pointer;
}

.appWrap .card .sides .right .resultPanel .results .result .toggleDetails svg {
  width: 18px;
  height: 18px;
  fill: #26768C;
  color: #26768C;
}
.appWrap .card .sides .right .resultPanel .results .result.active .toggleDetails svg {
  fill: #737373;
  color: #737373;
}

.appWrap .card .sides .right .resultPanel .results .result .name {
  font-size: 18px;
  line-height: 1.16;
}

.appWrap .card .sides .right .resultPanel .results .result .address {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.1;
}

.appWrap .card .sides .right .resultPanel .results .result .phone {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.1;
}

.result .details {
  border-top: 2px solid #dddddd;
  margin-top: 15px;
}

.result .details .detail {
  color: #454545;
  font-size: 14px;
  line-height: 1.1;
  border-bottom: 1px solid #d5edf3;
  padding: 10px 0;
}

.result .details .detail span {
  color: #26768b;
  font-weight: bold;
}

/*
 Breakpoints
 1420
 920
 718
*/

@media (max-width: 1100px) {

  .appWrap {
    padding: 40px 20px;
  }

  .appWrap .card .search .submit,
  .appWrap .card .search .clear,
  .appWrap .card .search .gps {
    padding: 20px;
  }
  .appWrap .card .sides .left {
    padding: 18px 18px 50px 18px;
    width: 300px;
    min-width: 300px;
  }
  .appWrap .card .sides .right.detailsShowing #mapView {
    margin-right: 260px;
  }
  .appWrap .card .sides .right .resultPanel {
    width: 300px;
  }

}
@media (max-width: 768px) {

  .appWrap {
    padding: 0;
  }
  .appWrap .card {
    border-radius: 0;
    box-shadow: none;
  }
  .appWrap .card .search input {
    font-size: 20px;
    line-height: 1;
  }
  .appWrap .card .search .submit svg,
  .appWrap .card .search .clear svg,
  .appWrap .card .search .gps svg {
    width: 20px;
    height: 20px;
  }
  .appWrap .card .sides {
    position: relative;
  }
  .appWrap .card .sides .left {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    min-width: 0;
    padding: 15px 15px 0 15px;
  }
  .appWrap .card .sides .right {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50%;
  }
  .appWrap .card .sides .right.detailsShowing #mapView {
    margin-right: 0;
  }
  .appWrap .card .sides .left .heading {
    display: none;
  }
  .appWrap .card .sides .left .filterCheckboxes {
    border-bottom: 0;
    margin-top: 15px;
  }
  .appWrap .card .sides .right .resultPanel {
    position: fixed;
    top: 125px;
    bottom: 0;
    border-bottom-left-radius: 0;
    padding-bottom: 0;
    width: 50%;
    min-width: 300px;
  }
  .appWrap .card .sides .right .resultPanel.hidden {
    margin-right: -450px;
  }
  .appWrap .card .sides .right .resultPanel .heading span {
    margin: 10px;
  }
  .appWrap .card .sides .right .resultPanel .results {
    border-bottom: 0;
  }
  #mapView {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  #mapCanvas {
    border-radius: 0;
  }
  #mapControls {
    padding: 0;
  }
  #sliderDiv {
    margin: 0 20px;
    padding: 15px 20px 35px 20px;
  }
  #sliderDiv .esri-slider__max,
  #sliderDiv .esri-slider__min {
    bottom: -30px;
    display: none;
  }
  #zoomDiv {
    display: none;
  }

}
